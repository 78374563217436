/*eslint-disable*/
import React from "react";

import { labUrl } from "../conf/conf";

export default {
  en: {
    quote:
      "For us, the Jawg experience is downright positive. I would recommend Jawg for their professionalism and also the great quality of their back office",
    author: "Christophe Chaumard, Technical Director",
    sector: "Relocation Assistance Services",
    interviewees: ["Christophe Chaumard, Technical Director"],
    service: "Dynamic Maps",
    geo: "France",
    blocks: [
      {
        title: "Ariase",
        description: (
          <span>
            The purpose of BeMove Group is to facilitate the relocation and
            mobility of their clients. In this ecosystem,{" "}
            <a
              href="https://www.ariase.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Ariase
            </a>{" "}
            offers tests of eligibility for internet services, critical to know
            what will be the best offer in terms of internet speed and cost. As
            Technical Director, Christophe Chaumard is in charge of the
            technical team. He deals with different projects, managing planning
            and various R &amp; D topics.
          </span>
        ),
        img: "clientLogo",
      },
      {
        title: "Ariase & Jawg",
        description:
          "On their website, Ariase wanted to offer their users an interactive map allowing to vizualise the internet and data coverage. The map was such a success that it attracted a lot of traffic on the website, and therefore lead to a high volume, and a high cost. This is what led Christophe to seek for an alternative.",
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            When he discovered Jawg, he was happy to meet a French actor who
            could answer his needs: <q>I found it very interesting</q>. It's as
            much the technology as the agreement on adequate pricing that
            convinced him.{" "}
            <q>
              Once we were reassured technically how to integrate it, it was
              only a matter of price, we managed to find an agreement, so it was
              perfect
            </q>
            .
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            In the course of their project, they managed to switch their maps to
            the new vector maps technology, which offers a much smoother user
            experience, and reduces the consumption of tiles.{" "}
            <q>The payoff is pretty incredible</q> says Christophe.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Christophe especially appreciated the back-office of{" "}
            <a
              href={labUrl}
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              JawgLab
            </a>{" "}
            in the beginning, which allowed him to see which maps responded well
            to users.{" "}
            <q>
              It was impressive to us to see all those statistics in real time
            </q>
            .
          </span>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Responsive, Professional and Friendly",
      },
    ],
  },
  fr: {
    quote:
      "Pour nous, l’expérience Jawg est carrément positive. Je recommanderais Jawg pour le professionnalisme et la qualité du back office",
    author: "Christophe Chaumard, Directeur technique",
    sector: "Services d’aide au déménagement",
    interviewees: ["Christophe Chaumard, Directeur technique"],
    service: "Dynamic Maps",
    geo: "France",
    blocks: [
      {
        title: "Ariase",
        description: (
          <span>
            Le groupe BeMove a pour mission de faciliter le déménagement et la
            mobilité de leurs clients. Dans cet écosystème,{" "}
            <a
              href="https://www.ariase.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Ariase
            </a>{" "}
            propose des services de tests d’éligibilité sur internet, afin
            vérifier quelle sera la meilleure offre en terme de débit et de coût
            pour le client. En tant que Directeur Technique, Christophe Chaumard
            est en charge de l’équipe technique du groupe BeMove. Il s’occupe de
            différents projets, autant au niveau planning, que sur des sujets de
            R&#38;D.
          </span>
        ),
        img: "clientLogo",
      },
      {
        title: "Ariase & Jawg",
        description:
          "Sur leur site, Ariase a voulu proposer à leurs utilisateurs de visualiser la couverture internet ou data sur des cartes. La carte a beaucoup plu sur le site, et c’est donc une forte volumétrie et des coûts difficiles à maîtriser qui ont amené Christophe à chercher une alternative.",
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            Lorsqu’il découvre Jawg, il est content de rencontrer un acteur
            français qui peut répondre à ses besoins :{" "}
            <q>&#160;J’ai trouvé ça très intéressant&#160;</q>. C’est autant la
            technologie que l’entente sur un pricing adéquat qui l’a convaincu.{" "}
            <q>
              &#160;Une fois qu’on a été rassuré sur techniquement comment
              l’intégrer, ce n’était plus qu’une question de prix, on a réussi à
              trouver une entente, donc c’était parfait&#160;
            </q>
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Dans la suite de leur projet, ils ont basculé leurs cartes sur des
            technologies de cartes vectorielles, qui apportent une grande
            fluidité dans l’expérience utilisateur, et réduisent la consommation
            de tuiles. <q>&#160;Le gain est assez incroyable&#160;</q>{" "}
            témoigne-t-il.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Christophe a particulièrement apprécié le back-office du{" "}
            <a
              href={labUrl}
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              JawgLab
            </a>{" "}
            au démarrage du projet, qui lui a permis de voir quelles étaient les
            cartes qui réagissent bien auprès des utilisateurs.{" "}
            <q>
              &#160;ça nous a impressionné d’avoir toutes ces statistiques en
              temps réel&#160;
            </q>
            .
          </span>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Réactif, Professionnel et Sympathique",
      },
    ],
  },
};
