import { graphql, useStaticQuery } from "gatsby";

export const useQuery = () =>
  useStaticQuery(graphql`
    query {
      clientLogo: file(
        relativePath: { eq: "Logos-client/Largeur variable/Color/Ariase.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      clientCover: file(relativePath: { eq: "interview/Ariase-top.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      usageHighlight: file(
        relativePath: { eq: "interview/Ariase-middle.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 2000, formats: [AUTO, WEBP, AVIF])
        }
      }
      clientLogoDark: file(
        relativePath: { eq: "Logos-client/Largeur variable/Dark/Ariase.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 250, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
